import { type Scalars } from '../__generated-gql-types__/globalTypes';

import { categorySlugToUrn } from './category';
import {
  BASE_LELAND_PLUS_MARKETING_URL,
  CAREER_CATEGORY_SLUG,
  COLLEGE_CATEGORY_SLUG,
  DEFERRED_MBA_SUBCATEGORY_SLUG,
  LELAND_PLUS_REDIRECT_URL,
  MANAGEMENT_CONSULTING_CATEGORY_SLUG,
  MBA_CATEGORY_SLUG,
  PRODUCT_MANAGEMENT_CATEGORY_SLUG,
} from './constants';

const LELAND_PLUS_CATEGORIES: Array<Scalars['CategoryUrn']['output']> = [
  categorySlugToUrn(MBA_CATEGORY_SLUG),
  categorySlugToUrn(MANAGEMENT_CONSULTING_CATEGORY_SLUG),
  categorySlugToUrn(COLLEGE_CATEGORY_SLUG),
  categorySlugToUrn(CAREER_CATEGORY_SLUG),
  categorySlugToUrn(PRODUCT_MANAGEMENT_CATEGORY_SLUG),
];

export const isLiveLelandPlusCategory = (
  categoryUrn: Scalars['CategoryUrn']['output'],
): boolean => LELAND_PLUS_CATEGORIES.includes(categoryUrn);

export const getLelandPlusLandingPageUrl = ({
  isLelandPlusUser,
  categorySlug,
  subCategorySlug,
  utmMedium,
  utmCampaign,
}: {
  isLelandPlusUser: boolean;
  categorySlug: Possible<string>;
  subCategorySlug?: Possible<string>;
  utmMedium: string;
  utmCampaign?: string;
}) => {
  if (isLelandPlusUser) return LELAND_PLUS_REDIRECT_URL;

  const baseUrl = new URL(
    `${BASE_LELAND_PLUS_MARKETING_URL}/${
      !categorySlug
        ? ''
        : `${categorySlug}${
            subCategorySlug === DEFERRED_MBA_SUBCATEGORY_SLUG
              ? `/${subCategorySlug}`
              : ''
          }`
    }`,
  );
  baseUrl.searchParams.set('utm_source', 'site');
  baseUrl.searchParams.set('utm_medium', utmMedium);
  if (utmCampaign) baseUrl.searchParams.set('utm_campaign', utmCampaign);
  return baseUrl.toString();
};
