import {
  type Class,
  type Shindig,
} from '../__generated-gql-types__/globalTypes';

export const getCategoryForEvent = (
  itemCategorySlugs: string[],
  eventCategorySlugs: string[],
) => {
  const eventCategory = itemCategorySlugs.find((category) =>
    eventCategorySlugs.includes(category),
  );
  return eventCategory ?? eventCategorySlugs[0];
};

export const addUTMToEventLink = (link: string, utmMedium: string) => {
  try {
    const url = new URL(link);
    url.searchParams.set('utm_source', 'site');
    url.searchParams.set('utm_medium', utmMedium);
    return url.toString();
  } catch {
    return link;
  }
};

export const isEventShindig = <
  S extends Pick<Shindig, '__typename'>,
  C extends Pick<Class, '__typename'>,
>(
  event: S | C | undefined,
): event is S => {
  return event?.__typename === 'Shindig';
};
