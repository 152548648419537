import { type LelandPlusImageCtaProps } from '@leland-dev/leland-ui-library';
import Image from 'next/image';

import CasingDrillsImage from '../../assets/images/leland-plus-banner/casing-drills.png';
import CoachVideosImage from '../../assets/images/leland-plus-banner/coach-videos.png';
import CompanyDeepDivesImage from '../../assets/images/leland-plus-banner/company-deep-dives.png';
import ExampleCasesImage from '../../assets/images/leland-plus-banner/example-cases.png';
import ExampleEssaysImage from '../../assets/images/leland-plus-banner/example-essays.png';
import ExampleResumesConsultingImage from '../../assets/images/leland-plus-banner/example-resumes-consulting.png';
import ExampleResumesEducationImage from '../../assets/images/leland-plus-banner/example-resumes-education.png';
import ExampleResumesPmImage from '../../assets/images/leland-plus-banner/example-resumes-pm.png';
import ExampleResumesImage from '../../assets/images/leland-plus-banner/example-resumes.png';
import InterviewPrepImage from '../../assets/images/leland-plus-banner/interview-prep.png';

export const LelandPlusSchoolImages: LelandPlusImageCtaProps[] = [
  {
    text: 'Example Essays',
    image: (
      <Image
        key="example-essays"
        src={ExampleEssaysImage}
        alt="Example Essays Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Example Resumes',
    image: (
      <Image
        key="example-resumes"
        src={ExampleResumesEducationImage}
        alt="Example Resumes Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Application Prep',
    image: (
      <Image
        key="application-prep"
        src={InterviewPrepImage}
        alt="Application Prep Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Video Courses',
    image: (
      <Image
        key="coach-videos"
        src={CoachVideosImage}
        alt="Video Courses Image"
        width={210}
        height={125}
      />
    ),
  },
];

export const LelandPlusCareerImages: LelandPlusImageCtaProps[] = [
  {
    text: 'Example Resumes',
    image: (
      <Image
        key="example-resumes"
        src={ExampleResumesImage}
        alt="Example Resumes Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Interview Prep',
    image: (
      <Image
        key="interview-prep"
        src={InterviewPrepImage}
        alt="Interview Prep Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Company Deep Dives',
    image: (
      <Image
        key="company-deep-dives"
        src={CompanyDeepDivesImage}
        alt="Company Deep Dives Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Video Courses',
    image: (
      <Image
        key="coach-videos"
        src={CoachVideosImage}
        alt="Video Courses Image"
        width={210}
        height={125}
      />
    ),
  },
];

export const LelandPlusProductManagementImages: LelandPlusImageCtaProps[] = [
  {
    text: '150+ Video Guides',
    image: (
      <Image
        key="coach-videos"
        src={CoachVideosImage}
        alt="Video Guides Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Example Resumes',
    image: (
      <Image
        key="example-resumes"
        src={ExampleResumesPmImage}
        alt="Example Resumes Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Exercises & Templates',
    image: (
      <Image
        key="exercises-templates"
        src={CasingDrillsImage}
        alt="Exercises & Templates Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Technical Skill Development',
    image: (
      <Image
        key="technical-skill-development"
        src={ExampleCasesImage}
        alt="Technical Skill Development Image"
        width={210}
        height={125}
      />
    ),
  },
];

export const LelandPlusConsultingImages: LelandPlusImageCtaProps[] = [
  {
    text: 'Example Resumes',
    image: (
      <Image
        key="example-resumes"
        src={ExampleResumesConsultingImage}
        alt="Example Resumes Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Example Cases',
    image: (
      <Image
        key="example-cases"
        src={ExampleCasesImage}
        alt="Example Cases Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Casing Drills',
    image: (
      <Image
        key="casing-drills"
        src={CasingDrillsImage}
        alt="Casing Drills Image"
        width={210}
        height={125}
      />
    ),
  },
  {
    text: 'Mock Interviews',
    image: (
      <Image
        key="mock-interviews"
        src={CoachVideosImage}
        alt="Mock Interviews Image"
        width={210}
        height={125}
      />
    ),
  },
];
